import React, { useState } from 'react';
import NavBar from './components/NavBar';
import SearchBar from './components/SearchBar';
import SearchResults from './components/SearchResults';
import Pagination from './components/Pagination';
import Login from './components/Login';  // Importar componente Login
import { fetchSearchResults } from './services/api';

function App() {
    const [results, setResults] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('all');

    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token')); // Verificar si el usuario está autenticado

    const resultsPerPage = parseInt(process.env.REACT_APP_RESULTS_PER_PAGE, 25) || 25;


    const handleSearch = async (searchQuery, collection) => {
        try {
            setQuery(searchQuery);
            setSelectedCollection(collection);
            const data = await fetchSearchResults(searchQuery, collection, 1, resultsPerPage);
            setResults(data.results);
            setTotalResults(data.total);
            setTotalPages(Math.ceil(data.total / resultsPerPage));
            setCurrentPage(1);
        } catch (error) {
            console.error('Error al buscar:', error);
        }
    };

    const handlePageChange = async (page) => {
        try {
            const data = await fetchSearchResults(query, selectedCollection, page, resultsPerPage);
            setResults(data.results);
            setCurrentPage(page);
        } catch (error) {
            console.error('Error al cambiar de página:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token'); // Eliminar el token del localStorage
        setIsAuthenticated(false); // Actualizar el estado de autenticación
    };

    if (!isAuthenticated) {
        return <Login onLogin={() => setIsAuthenticated(true)} />;
    }

    return (
        <div className="App">
            <NavBar onLogout={handleLogout} /> {/* Pasar la función de logout */}
            <SearchBar onSearch={handleSearch} />
            <SearchResults results={results} />
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
}

export default App;