import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import './SearchResults.css';

const SearchResults = ({ results }) => {
    // Si no hay resultados, inicializamos con una estructura vacía para evitar errores
    const emptyResults = useMemo(() => results.length === 0 ? [{}] : results, [results]);

    // Obtener dinámicamente las claves de las columnas estándar desde los resultados
    const standardColumns = useMemo(() => {
        return Object.keys(emptyResults[0]).filter(key => key !== "samples").map(key => ({
            Header: key.charAt(0).toUpperCase() + key.slice(1),
            accessor: key,
        }));
    }, [emptyResults]);

    // Obtener las muestras dinámicamente desde la clave "samples"
    const sampleColumns = useMemo(() => {
        return emptyResults.length > 0 && emptyResults[0].samples
            ? Object.keys(emptyResults[0].samples).map(sample => ({
                Header: sample,
                accessor: `samples.${sample}`,
            }))
            : [];
    }, [emptyResults]);

    // Combinar columnas estándar y columnas de muestras
    const columns = useMemo(() => [...standardColumns, ...sampleColumns], [standardColumns, sampleColumns]);

    const data = useMemo(() => results, [results]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy   // Usar funcionalidad de ordenación
    );

    return (
        <div className="search-results">
            {results.length === 0 ? (
                <p>No se encontraron resultados.</p>
            ) : (
                <table {...getTableProps()} className="styled-table">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Añadir un indicador para la ordenación */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SearchResults;