import React from 'react';

const Pagination = ({ currentPage, totalPages, totalResults, resultsPerPage, onPageChange }) => {
    const startResult = (currentPage - 1) * resultsPerPage + 1;
    const endResult = Math.min(currentPage * resultsPerPage, totalResults);

    const pages = [];
    const firstPages = 3;

    if (totalPages > 1) {
        // Añadir botón "Anterior"
        if (currentPage > 1) {
            pages.push(
                <button key="prev" onClick={() => onPageChange(currentPage - 1)}>
                    Anterior
                </button>
            );
        }

        // Mostrar las primeras 3 páginas
        for (let i = 1; i <= Math.min(firstPages, totalPages); i++) {
            pages.push(
                <button
                    key={i}
                    className={i === currentPage ? 'active' : ''}
                    onClick={() => onPageChange(i)}
                    style={{ fontWeight: i === currentPage ? 'bold' : 'normal' }} // Negrita en la página actual
                >
                    {i}
                </button>
            );
        }

        // Añadir el botón de la última página
        if (totalPages > firstPages + 1) {
            pages.push(<span key="ellipsis">...</span>);
            pages.push(
                <button
                    key={totalPages}
                    className={totalPages === currentPage ? 'active' : ''}
                    onClick={() => onPageChange(totalPages)}
                    style={{ fontWeight: totalPages === currentPage ? 'bold' : 'normal' }}
                >
                    {totalPages}
                </button>
            );
        }

        // Añadir botón "Siguiente"
        if (currentPage < totalPages) {
            pages.push(
                <button key="next" onClick={() => onPageChange(currentPage + 1)}>
                    Siguiente
                </button>
            );
        }
    }

    return (
        <div className="pagination">
            {/* Mostrar el rango de resultados y la página actual */}
            <p>
               Resultados del <strong>{startResult}</strong> al <strong>{endResult}</strong> de <strong>{totalResults}</strong>.
            </p>
            <div>{pages}</div>
        </div>
    );
};

export default Pagination;