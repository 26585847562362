import React, { useEffect, useState } from 'react';
import './SearchBar.css';
import { fetchCollections } from '../services/api';

const SearchBar = ({ onSearch }) => {
    const [query, setQuery] = useState('');
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('all');

    useEffect(() => {
        const loadCollections = async () => {
            const data = await fetchCollections();
            setCollections(['all', ...data]);
        };
        loadCollections();
    }, []);

    const handleSearch = () => {
        onSearch(query, selectedCollection);
    };
    //console.log(selectedCollection)
    return (
        <div className="search-bar">
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Buscar..."
            />
            <select
                value={selectedCollection}
                onChange={(e) => setSelectedCollection(e.target.value)}
            >
                {collections.map((collection) => (
                    <option key={collection} value={collection}>
                        {collection === 'all' ? 'Buscar en todos los archivos' : collection}
                    </option>
                ))}
            </select>
            <button onClick={handleSearch}>Buscar</button>
        </div>
    );
};

export default SearchBar;