import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';  

// Función para realizar la autenticación y obtener el token
export const loginUser = async (username, password) => {
    const endpoint = "/api/v1/auth/token/"; 
    const url = `${API_URL}${endpoint}`;
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    const response = await axios.post(url, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return response.data;
};

// Obtener las colecciones disponibles desde el backend
export const fetchCollections = async () => {
    const endpoint = "/api/v1/search/collections";
    const url = `${API_URL}${endpoint}`
    const token = localStorage.getItem('token'); 
    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error to get collections:', error);
        if (error.response.status === 401) {
            localStorage.removeItem('token'); // Limpiar el token si está expirado
            window.location.reload(); // Redirigir al login
        }
        throw error;
    }
};

export const fetchSearchResults = async (query, collections, page = 1, limit = 30) => {
    const endpoint = "/api/v1/search/";
    const url = `${API_URL}${endpoint}`
    const token = localStorage.getItem('token'); 
    try {
        const response = await axios.get(url, {
            params: {
                query,
                collections,
                page,
                limit
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al buscar:', error);
        if (error.response.status === 401) {
            localStorage.removeItem('token'); // Limpiar el token si está expirado
            window.location.reload(); // Redirigir al login
        }
        throw error;
    }
};