import React from 'react';
import './NavBar.css'; // Archivo de CSS para estilos

const NavBar = ({ onLogout }) => {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src="/logo.png" alt="Logo" />
                <h1>Buscador de Variantes Concha & Toro</h1>
            </div>
            <ul className="navbar-links">
                <li><a href="/">Inicio</a></li>
                <li><a href="/Search">Buscar variantes</a></li>
                <li><a href="/about">Acerca de</a></li>
                <li><a href="/contact">Contacto</a></li>
                <li><a href="/" onClick={(e) => { e.preventDefault(); onLogout(); }}>Logout</a></li>
            </ul>
        </nav>
    );
};

export default NavBar;